export const iconInInputStyle = {color: "#6c757d", fontSize: "1.2rem"};
export const iconInPreviousStyle = {
    color: "#84665b",
    fontSize: "1.5rem",
    marginRight: "10px",
    cursor: "pointer",
    textAlign: "center",
};

export const iconInPreviousInsideComponentsStyleDark = {
    color: "#ffffff",
    marginLeft: "12px",
    marginTop: "15px",
    fontSize: "1.5rem",
    cursor: "pointer",
    textAlign: "center",
};

export const iconInPreviousInsideComponentsStyleLight = {
    color: "#84665b",
    fontSize: "1.5rem",
    marginTop: "9px",
    cursor: "pointer",
    textAlign: "center",
};

export const excelIconConfig = {
    color: "#34a3a3",
    fontSize: "1.6rem",
    marginRight: "1px",
};

export const iconInModelDialogStyle = {
    color: "#84665b",
    fontSize: "1rem",
    marginRight: "3px",
};
export const iconInModelDialogStyleSupplier = {
    color: "#84665b",
    fontSize: "1.05rem",
    marginRight: "10px",
};
export const iconInModelDialogStyleDeleteTicket = {
    color: "#C70000",
    fontSize: "1.05rem",
    marginRight: "10px",
};
export const iconInModelDialogStyleViewTicket = {
    color: "#84665b",
    fontSize: "1.05rem",
    marginRight: "10px",
};
export const iconInModelDialogSupplierStyle = {
    color: "#ffffff",
    fontSize: "3.3rem",
    marginRight: "3px",
};

export const iconUserInNotification = {
    color: "#ffffff",
    fontSize: "2.5rem",
};
export const InfolIconConfig = {color: "#B08968", fontSize: "1.2rem"};
export const discountIconConfig = {color: "#B08968", fontSize: "1.6rem"};

export const fullWidthIconConfig = {color: "#000000", fontSize: "1.5rem"};

export const sizeChevronUpAndDownInTable = {fontSize: "1.3rem"};
export const menuThreeDots = {fontSize: "1.3rem", color: "#adb5bd"};

export const configChevLeftIcon = {color: "#84665b", fontSize: "2.5rem"};
export const calendarIconConfig = {color: "#858897", fontSize: "1.5rem"};
export const calendarIconConfigUpdate = {color: "#495057", fontSize: "1.5rem"};

export const socialMediaLink = {
    color: "#fff",
    fontSize: "2rem",
    marginLeft: "20px",
};

export const socialMediaLinkSmall = {
    color: "#fff",
    fontSize: "1.5rem",
    marginLeft: "10px",
    marginRight: "10px",
};

export const iconContactForm = {
    color: "#fff",
    fontSize: "2.5rem",
    marginRight: "10px",
};

export const iconContactFormSmall = {
    color: "#fff",
    fontSize: "1.5rem",
    marginRight: "3px",
};

export const iconEditPlans = {
    color: "#fff",
    fontSize: "1.2rem",
    marginLeft: "9px",
};

export const iconCopyConfig = {
    color: "#84665b",
    fontSize: "1.8rem",
    marginBottom: "14px",
};

export const iconCloseIcon = {
    color: "#adb5bd",
    fontSize: "1.8rem",
};

export const accountType = [
    {label: "Ho.Re.Ca", value: "HORECA"},
    {label: "Studi di settore", value: "ACCOUNTANT"},
];


export const planMode = [
    {label: "Mensile", value: "MONTHLY"},
    {label: "Annuale", value: "YEARLY"},
];


export const allUsersForRadio = "allUsers";
export const accountTypeForRadio = "accountType";
export const specifiqueUserRadio = "specifiqueUser";
export const horecaType = "HORECA";
export const accountantType = "ACCOUNTANT";

export const guestRoutes = [
    "/auth/confirmation",
    "/auth/account/confirm",
    "/auth/login",
    "/auth/register",
    "/auth/forgot-password",
    "/auth/forgot-password/confirmation",
    "/auth/account/resetPassword",
    "/auth/two-steps",
    "/auth/maintenance",
    "/auth/confirmationMailing",
];

export const routeToGetAllAccountant =
    "/admin/users/management/edit?accountType=ACCOUNTANT";
export const routeToGetAllHoreca =
    "/admin/users/management/edit?accountType=HORECA";


export const routeToAddGeneraleCoupon =
    "/admin/discount/create/generale";
export const routeToAddSpecificCoupon =
    "/admin/discount/create?couponType=SPECIFIQUE";


export const succesPayment = "success";
export const cancelPayment = "cancellation";
export const failurePayment = "failure";

export const horecaPlans = "/admin/dashboard/plans/horeca";
export const accountantPlans = "/admin/dashboard/plans/accountant";


// Fields that should always remain as strings
export const stringFields = [
    'IdCodice',
    'CodiceFiscale',
    'ProgressivoInvio',
    'Numero',
    'CAP',
    'IBAN'
];

// Fields that should be converted to numbers
export const numericFields = [
    'ImportoTotaleDocumento',
    'PrezzoUnitario',
    'PrezzoTotale',
    'Quantita',
    'AliquotaIVA',
    'ImportoPagamento',
    'GiorniTerminiPagamento',
    'ImponibileImporto',
    'Imposta',
    'CapitaleSociale'
];


export const updateQuotaAction = "updateQuota";
export const resetQuotaAction = "resetQuota";

