import React, {useState, useEffect} from 'react';
import {Grid} from '@mui/material';
import {connect, useSelector} from 'react-redux';
import PageContainer from '../../components/container/PageContainer';
import Spinner from '../../components/shared/Spinner';
import {
    getQuotaLocalInfo,
    getQuotaAiProviderInfo,
    resetQuota,
    addNewQuota,
    deleteRelatedInvoiceInfoByUser
} from './service/QuotaService';
import {
    getAllUsersByCompanyName,
    getAllUsers
} from "../admin/account/service/AccountService";
import LocalQuotaCard from './components/LocalQuotaCard';
import ProviderQuotaCard from './components/ProviderQuotaCard';
import UpdateQuotaCard from './components/UpdateQuotaCard';
import ResetUserInvoiceCard from './components/ResetUserInvoiceCard';
import swal from 'sweetalert';
import "./style/QuotaStyle.css";

const QuotaInfo = ({
                       getQuotaLocalInfo,
                       getQuotaAiProviderInfo,
                       resetQuota,
                       addNewQuota,
                       deleteRelatedInvoiceInfoByUser,
                       getAllUsersByCompanyName,
                       getAllUsers
                   }) => {
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const {quotaInfo} = useSelector((state) => state.aiQuotaReducer);
    const {userList} = useSelector((state) => state.adminAccountReducer);

    useEffect(() => {
        setLoading(true);
        Promise.all([getQuotaLocalInfo(), getAllUsers(0, 10)]).then(() => setLoading(false));
    }, []);

    const handleSearchUsers = async (companyName) => {
        try {
            setSearchLoading(true);
            if (!companyName || companyName.trim() === '') {
                // Reset to initial list if search is empty
                await getAllUsers(0, 10);
            } else {
                await getAllUsersByCompanyName(0, 10, companyName);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            // If there's an error, reset to initial list
            await getAllUsers(0, 10);
        } finally {
            setSearchLoading(false);
        }
    };

    const getQuotaInfoFromProvider = async () => {
        setLoadingButton(true);
        await getQuotaAiProviderInfo();
        setLoadingButton(false);
    };

    const getQuotaInfoFromLocal = async () => {
        setLoadingButton(true);
        await getQuotaLocalInfo();
        setLoadingButton(false);
    };

    const handleSubmit = async ({type, data}) => {
        try {
            if (type === 'resetQuota') {
                await resetQuota();
            } else if (type === 'updateQuota') {
                await addNewQuota(data.newQuotaCount);
            }
            await getQuotaInfoFromLocal();
        } catch (error) {
            console.error('Error in handleSubmit:', error);
        }
    };

    const onSubmitResetUserData = async (formData) => {
        try {
            // Find the full user object based on the selected userProfile
            const selectedUser = userList.find(user =>
                user.userProfile.companyName === formData.userUuid.companyName
            );

            console.log("Selected user:", selectedUser);

            const willReset = await swal({
                title: "Are you sure?",
                text: `You are about to reset data for user: ${formData.userUuid.companyName}`,
                icon: "warning",
                buttons: ["Cancel", "Yes, reset it!"],
                dangerMode: true,
            });

            if (willReset) {
                await deleteRelatedInvoiceInfoByUser(selectedUser.userProfile.uuid);

            }
        } catch (error) {
            await swal({
                title: "Error!",
                text: "An unexpected error occurred",
                icon: "error",
                button: "OK"
            });
            console.error('Error:', error);
        }
    };

    if (loading) return <Spinner/>;

    return (
        <PageContainer title="Quota AI">
            <Grid container spacing={4} mt={4}>
                <Grid item xs={6} lg={6} sm={12}>
                    <LocalQuotaCard
                        quotaInfo={quotaInfo}
                        getQuotaInfoFromProvider={getQuotaInfoFromProvider}
                        loadingButton={loadingButton}
                    />
                    <ProviderQuotaCard
                        quotaInfo={quotaInfo}
                        getQuotaInfoFromLocal={getQuotaInfoFromLocal}
                        loadingButton={loadingButton}
                    />
                </Grid>

                <Grid item xs={6} lg={6} sm={12}>
                    <ResetUserInvoiceCard
                        onSubmitResetUserData={onSubmitResetUserData}
                        users={userList}
                        searchLoading={searchLoading}
                        onSearchUsers={handleSearchUsers}
                    />
                </Grid>

                <Grid item xs={6} lg={6} sm={12}>
                    <UpdateQuotaCard onSubmit={handleSubmit}/>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

const mapStateToProps = (state) => ({
    aiQuotaReducer: state.aiQuotaReducer,
    adminAccountReducer: state.adminAccountReducer
});

export default connect(mapStateToProps, {
    getQuotaLocalInfo,
    getQuotaAiProviderInfo,
    resetQuota,
    addNewQuota,
    deleteRelatedInvoiceInfoByUser,
    getAllUsersByCompanyName,
    getAllUsers
})(QuotaInfo);