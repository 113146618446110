import React, { useEffect, useRef } from "react";
import {connect, useSelector} from "react-redux";
import {
  getLastSystemOperation,
  subscribeToSystemOperations,
} from "src/modules/systemOperation/service/SystemOperationService";
import LinearProgressWithLabel from "src/components/shared/LinearProgressWithLabel";
import { getJwt, hasClientRole } from "src/utils/decodeJwtCurrentUser";

const SuccesOperationChips = ({
                                getLastSystemOperation,
                                subscribeToSystemOperations,
                              }) => {
  const { lastOperation } = useSelector(
      (state) => state.systemOperationReducer
  );

  // Ref to track subscription status
  const hasSubscribed = useRef(false);

  // Fetch the last operation on components mount
  useEffect(() => {
    getLastSystemOperation();
  }, [getLastSystemOperation]);

  // Subscribe to system operations when lastOperation updates
  useEffect(() => {
    if (
        lastOperation !== null &&
        !lastOperation.passed &&
        !hasSubscribed.current
    ) {
      subscribeToSystemOperations(lastOperation);
      hasSubscribed.current = true;
    }
  }, [lastOperation, subscribeToSystemOperations]);

  // GET ROLES FROM JWT
  const isHoreca = hasClientRole(getJwt());
  const isAccountant = hasClientRole(getJwt());

  return (
      <div>
        {(isHoreca || isAccountant) && lastOperation !== null ? (
            !lastOperation.passed ? (
                <LinearProgressWithLabel />
            ) : (
                ""
            )
        ) : null}
      </div>
  );
};

export default connect(null, {
  getLastSystemOperation,
  subscribeToSystemOperations,
})(SuccesOperationChips);
