import dayjs from "dayjs";
// Day.js plugin for advanced formatting
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/it"; // Load Italian locale
import relativeTime from "dayjs/plugin/relativeTime"; // Load relative time plugin
import moment from "moment";

export const formatToDate = (inputDate) => {
  dayjs.extend(customParseFormat);

  // Parse and format the date
  const date = dayjs(inputDate, "ddd, DD MMM YYYY HH:mm:ss [GMT]");

  // Format the date to 'YYYY-MM-DD'
  return date.format("YYYY-MM-DD");
};

export const formatDateAgo = (dateToFormat) => {
  // Extend dayjs with relative time plugin
  dayjs.extend(relativeTime);
  // Set locale to Italian
  dayjs.locale("it");
  // Format the provided date string into a human-readable relative time
  return dayjs(dateToFormat).fromNow();
};

export const formatItalianDate = (inputDate) => {
  // Set locale to Italian
  dayjs.locale("it");

  return dayjs(inputDate).format("dddd MMMM D, YYYY H:mm");
};

export const formatItalianDateUpdated = (inputDate) => {
  // Set locale to Italian
  dayjs.locale("it");

  return dayjs(inputDate).format("DD/MM/YY");
};

export const convertTimestamp = (timestamp) => {
  const millisecondsDifference = Math.abs(moment().diff(moment(timestamp)));

  if (millisecondsDifference < 60000) {
    // Less than 1 minute
    return `${Math.floor(millisecondsDifference / 1000)} seconds`;
  } else if (millisecondsDifference < 3600000) {
    // Less than 1 hour
    return `${Math.floor(millisecondsDifference / 60000)} minutes`;
  } else if (millisecondsDifference < 86400000) {
    // Less than 1 day
    return `${Math.floor(millisecondsDifference / 3600000)} hours`;
  } else {
    // More than 1 day
    return `${Math.floor(millisecondsDifference / 86400000)} days`;
  }
};

export const setFromDateIfNull = (from) => {
  // Check if 'from' is null, if so, set it to '1970-01-01'
  return from ? dayjs(from).format("YYYY-MM-DD") : "1970-01-01";
};

export const setToDateIfNull = (to) => {
  // Set 'to' date to today's date if it's null
  return to ? dayjs(to).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD");
};

export const formatDateToSentence = (dateToFormat) => {
  return dayjs(dateToFormat).locale("it").format("DD MMMM YYYY");
};

export const formatDateItalianShort = (inputDate) => {
  // Parse the input date using dayjs
  const parsedDate = dayjs(inputDate);

  // Format the date as "DD MMM" (e.g., "28 AVR")
  const formattedDate = parsedDate.format("DD MMM hh:mm");

  return formattedDate;
};


export const parseToValidDate = (dateString) => {
  if (!dateString) {
    return null; // Return null if dateString is null or undefined
  }

  // Ensure the input is a string and trim whitespace
  let validString = dateString.toString().trim();

  if (!validString) {
    return null; // Return null if dateString is empty after trimming
  }

  // Normalize the date string to ISO 8601 format
  const timezoneRegex = /([+-]\d{2}:\d{2})$/;
  if (timezoneRegex.test(validString) && !validString.includes('T')) {
    // Insert 'T00:00:00' before the timezone offset
    validString = validString.replace(timezoneRegex, 'T00:00:00$1');
  } else if (!validString.includes('T')) {
    // Append 'T00:00:00' if 'T' is missing
    validString += 'T00:00:00';
  }

  // Parse the date string using Moment.js with parseZone to preserve the time zone
  const parsedDate = moment.parseZone(validString, moment.ISO_8601, true);

  // Check if the parsed date is valid
  if (!parsedDate.isValid()) {
    console.warn('Invalid date encountered:', dateString); // Debugging aid
    return null; // Return null if the date is invalid
  }

  // Use 'YYYY-MM-dd' as the desired output format
  const outputFormat = 'YYYY-MM-dd';

  // Replace 'dd' with 'DD' to get the day of the month
  const momentFormat = outputFormat.replace(/dd/g, 'DD');

  // Return the date in the desired format, preserving the original time zone
  return parsedDate.format(momentFormat);
};

