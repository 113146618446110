import axios from "axios";
import {environment} from "../../../environement/environment";
import {ADD_NEW_QUOTA, DELETE_RELATED_INVOICE_INFO, GET_QUOTA_INFO_LOCAL, RESET_QUOTA} from "./QuotaActionTypes";
import swal from "sweetalert";

export const getQuotaLocalInfo = () => async (dispatch) => {
    try {
        const response = await axios.get(
            `${environment.baseUrl}/ai_model/quota/info/local`
        );


        dispatch({
            type: GET_QUOTA_INFO_LOCAL,
            payload: {
                data: response.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};


export const getQuotaAiProviderInfo = () => async (dispatch) => {
    try {
        const response = await axios.get(
            `${environment.baseUrl}/ai_model/quota/info/ai_provider`
        );


        dispatch({
            type: GET_QUOTA_INFO_LOCAL,
            payload: {
                data: response.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};


export const addNewQuota = (newQuotation) => async (dispatch) => {
    try {
        const response = await axios.put(
            `${environment.baseUrl}/ai_model/quota?newQuotaCount=${newQuotation}`
        );


        dispatch({
            type: ADD_NEW_QUOTA,
            payload: {
                data: response.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const resetQuota = () => async (dispatch) => {
    try {
        const response = await axios.put(
            `${environment.baseUrl}/ai_model/quota/reset`
        );


        dispatch({
            type: RESET_QUOTA,
            payload: {
                data: response.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const deleteRelatedInvoiceInfoByUser = (customerUuid) => async (dispatch) => {
    try {
        const response = await axios.delete(
            `${environment.baseUrl}/dashboard_support?customerUuid=${customerUuid}`
        );


        dispatch({
            type: DELETE_RELATED_INVOICE_INFO,
            payload: {
                data: response.data,
            },
        });

        // Show success message
        await swal({
            title: "Success!",
            text: "User data has been reset successfully",
            icon: "success",
            timer: 2000,
            buttons: false
        });

    } catch (e) {
        await swal({
            title: "Error!",
            text: e.response.data.message,
            icon: "error",
            button: "OK"
        });
        console.log("INVALID", e);
    }
};


