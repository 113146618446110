// UpdateQuotaCard.jsx
import React from 'react';
import { Card, CardContent, Box, Typography, Divider, TextField, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import "../style/QuotaStyle.css";

const UpdateQuotaCard = ({ onSubmit }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            newQuotaCount: ''
        }
    });

    const quotaValue = watch('newQuotaCount');
    const isQuotaValid = !isNaN(quotaValue) && quotaValue !== '' && parseInt(quotaValue) >= 1;

    const handleUpdateSubmit = async (data) => {
        try {
            await onSubmit({
                type: 'updateQuota',
                data: data
            });
            reset();
        } catch (error) {
            console.error('Error in update submit:', error);
        }
    };

    const handleResetSubmit = async (e) => {
        e?.preventDefault();
        try {
            await onSubmit({
                type: 'resetQuota',
                data: { newQuotaCount: '' }
            });
            reset();
        } catch (error) {
            console.error('Error in reset submit:', error);
        }
    };

    return (
        <Card sx={{ maxWidth: 700, boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" component="div" color="primary">
                        Update Quota
                    </Typography>
                </Box>
                <Divider sx={{ my: 2 }}/>

                <Box component="form">
                    <Box sx={{ mb: 3 }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="New Quota Count"
                            type="number"
                            {...register('newQuotaCount', {
                                min: {
                                    value: 1,
                                    message: 'Minimum value is 1'
                                },
                                validate: value => {
                                    if (value === '') return true;
                                    return (!isNaN(value) && parseInt(value) >= 1) || 'Please enter a valid number';
                                }
                            })}
                            error={!!errors.newQuotaCount}
                            helperText={errors.newQuotaCount?.message}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputProps: { min: 1 } }}
                        />
                    </Box>

                    <Stack mt={2} spacing={2}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!isQuotaValid}
                            className="buttonHoverGetQuotaProvider"
                            onClick={handleSubmit(handleUpdateSubmit)}
                        >
                            Add New Quota
                        </LoadingButton>

                        <LoadingButton
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={isQuotaValid}
                            className="buttonHoverGetQuotaProvider"
                            onClick={handleResetSubmit}
                        >
                            Reset Quota
                        </LoadingButton>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    );
};

export default UpdateQuotaCard;