import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Box,
    Typography,
    Divider,
    TextField,
    Stack,
    Autocomplete,
    InputAdornment,
    CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { FiSearch } from 'react-icons/fi';
import "../style/QuotaStyle.css";

const ResetUserInvoiceCard = ({
                                  onSubmitResetUserData,
                                  users = [],
                                  searchLoading,
                                  onSearchUsers
                              }) => {
    const [inputValue, setInputValue] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            userUuid: null
        }
    });

    const userUuidValue = watch('userUuid');

    const iconInInputStyle = {
        color: '#666',
        width: '18px',
        height: '18px'
    };

    const handleSearchChange = (newInputValue) => {
        setInputValue(newInputValue);

        console.log(newInputValue)

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        const timeoutId = setTimeout(() => {
            onSearchUsers(newInputValue);
        }, 300);

        setSearchTimeout(timeoutId);
    };

    useEffect(() => {
        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        };
    }, [searchTimeout]);

    return (
        <Card sx={{ maxWidth: 700, height: 230, boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" component="div" color="primary">
                        Reset User Invoice Related Data
                    </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />

                <form onSubmit={handleSubmit(onSubmitResetUserData)}>
                    <Box sx={{ mb: 3 }}>
                        <Controller
                            name="userUuid"
                            control={control}
                            rules={{
                                required: 'User is required',
                                validate: value => {
                                    if (!value || !value.companyName) {
                                        return 'Please select a valid user';
                                    }
                                    return true;
                                }
                            }}
                            render={({ field: { onChange, value, ref } }) => (
                                <Autocomplete
                                    className="categoryParent"
                                    disablePortal
                                    options={users.map(user => user.userProfile) || []}
                                    loading={searchLoading}
                                    getOptionLabel={(option) =>
                                        option?.companyName || ''
                                    }
                                    value={value}
                                    onChange={(_, newValue) => {
                                        onChange(newValue);
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(_, newInputValue) => {
                                        handleSearchChange(newInputValue);
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                        option?.companyName === value?.companyName
                                    }
                                    renderOption={(props, option) => {
                                        const user = users.find(u => u.userProfile.companyName === option.companyName);
                                        return (
                                            <li {...props}>
                                                <div>
                                                    <div>{option.companyName}</div>
                                                    <div style={{ fontSize: '0.8em', color: '#666' }}>
                                                        {user?.uuid}
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="User *"
                                            placeholder="Search by company name..."
                                            error={!!errors.userUuid}
                                            helperText={errors.userUuid?.message}
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={ref}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    height: '44px',
                                                },
                                                '& .MuiInputBase-input::placeholder': {
                                                    textAlign: 'left',
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    padding: '3.5px 4px 7.5px 5px',
                                                },
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <>
                                                        {params.InputProps.startAdornment}
                                                        <InputAdornment position="start">
                                                            <FiSearch style={iconInInputStyle} />
                                                        </InputAdornment>
                                                    </>
                                                ),
                                                endAdornment: (
                                                    <>
                                                        {searchLoading ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>

                    <Stack mt={2} spacing={2}>
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!userUuidValue || !userUuidValue.companyName}
                            className="buttonHoverGetQuotaProvider"
                        >
                            Reset Invoices
                        </LoadingButton>
                    </Stack>
                </form>
            </CardContent>
        </Card>
    );
};

export default ResetUserInvoiceCard;