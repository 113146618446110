import {GET_QUOTA_INFO_LOCAL} from "../../modules/quotaAi/service/QuotaActionTypes";

const initialState = {

    quotaInfo: null,
};

export default function QuotaAiReducer(state = initialState, action) {
    switch (action.type) {


        case GET_QUOTA_INFO_LOCAL:
            return {
                ...state,
                quotaInfo: action.payload.data,
            };

        default: {
            return state;
        }
    }
}
