import axios from "axios";
import {environment} from "src/environement/environment";
import {
    CREATENEWCUSTOMER, GETALLUSERS, NOTIFICATIONSENDED,
} from "./AccountActionType";
import swal from "sweetalert";
import {formatToCapitalize} from "src/utils/formatRowValue";

export const getAllUsers = (page, limit) => async (dispatch) => {
    try {
        const response = await axios.get(`${environment.baseUrl}/dashboard_admin/users/all?page=${page}&limit=${limit}`);


        dispatch({
            type: GETALLUSERS, payload: {
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                totalElements: response.data.totalElements,
                data: response.data.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const getAllUsersByAccountType = (page, limit, accountType, companyName) => async (dispatch) => {
    try {
        // const response = await axios.get(}&accountType=${accountType}&companyName=${companyName}`);


        let baseUrl = `${environment.baseUrl}/dashboard_admin/users/byAccountType?page=${page}&limit=${limit}`;

        // Initialize an array to hold query string parts
        let queryParts = [];

        // Conditionally add parameters based on their existence
        if (accountType) queryParts.push(`accountType=${accountType}`);
        if (companyName) queryParts.push(`companyName=${companyName}`);

        // Join all parts together with '&', prefix with '&' if any queryParts exist
        const queryString =
            queryParts.length > 0 ? `&${queryParts.join("&")}` : "";

        const response = await axios.get(`${baseUrl}${queryString}`);

        dispatch({
            type: GETALLUSERS, payload: {
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                totalElements: response.data.totalElements,
                data: response.data.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const lockManagementUser = (payload) => async (dispatch) => {
    try {
        const response = await axios.put(`${environment.baseUrl}/dashboard_admin/ticket/updateUserAccountState`, payload);


        swal({
            title: "Fatto", text: response.data.message, icon: "success", button: "OK",
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const getAllUsersByCompanyName = (page, limit, companyName) => async (dispatch) => {
    try {
        const response = await axios.get(`${environment.baseUrl}/dashboard_admin/users/allByCompanyName?page=${page}&limit=${limit}&companyName=${companyName}`);

        dispatch({
            type: GETALLUSERS, payload: {
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                totalElements: response.data.totalElements,
                data: response.data.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const updateUserInfo = (payload, userRegistrationUuid) => async (dispatch) => {
    try {
        const response = await axios.put(`${environment.baseUrl}/dashboard_admin/users/updateUserPersonalData/${userRegistrationUuid}`, payload);


        swal({
            title: "Fatto", text: response.data.message, icon: "success", button: "OK",
        });
        dispatch({
            type: GETALLUSERS, payload: {
                data: response.data,
            },
        });
    } catch (e) {
        console.log("INVALID", e);
    }
};

export const registerUserByAdmin = (payload, navigate) => async (dispatch) => {
    try {
        const response = await axios.post(`${environment.baseUrl}/userRegistration/register`, payload);


        dispatch({
            type: CREATENEWCUSTOMER, payload: {data: false},
        });

        swal({
            title: "Fatto", text: "Utente creato con successo", icon: "success", button: "OK",
        });

        navigate("/admin/users/management/edit");

        return response;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.status === 500) {
            dispatch({
                type: CREATENEWCUSTOMER, payload: {data: true},
            });
            return e.response.data;
        }
        return false;
    }
};

export const sendGlobalNotification = (payload, navigate) => async (dispatch) => {
    try {
        const response = await axios.post(`${environment.baseUrl}/dashboard_admin/notification/global`, payload);


        dispatch({
            type: NOTIFICATIONSENDED, payload: {
                data: response.data,
            },
        });

        swal({
            title: "Fatto", text: response.data.message, icon: "success", button: "OK",
        });

        navigate("/admin/dashboard");
        return false;
    } catch (e) {
        if (e.response != null) {
            swal({
                title: "Richiesta non riuscita",
                text: ` ${formatToCapitalize(e.response.data.message)} `,
                icon: "error", // Change icon to 'error' for indicating an error
                button: "OK",
                dangerMode: true, // Set dangerMode to true to display the message with a red color
            });
        }
        console.log("INVALID", e);
        return false;
    }
};

export const updateFreeTrialService = (payload, userRegistrationUuid) => async (dispatch) => {
    try {
        const response = await axios.put(`${environment.baseUrl}/dashboard_admin/freeTrialManagement/${userRegistrationUuid}`, payload);


        swal({
            title: "Fatto", text: response.data.message, icon: "success", button: "OK",
        });
    } catch (e) {
        console.log("INVALID", e);
        if (e.response != null) {
            swal({
                title: "Richiesta non riuscita",
                text: ` ${formatToCapitalize(e.response.data.message)} `,
                icon: "error", // Change icon to 'error' for indicating an error
                button: "OK",
                dangerMode: true, // Set dangerMode to true to display the message with a red color
            });
        }
        console.log("INVALID", e);
        return false;
    }
};
