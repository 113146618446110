import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import AuthGuard from "src/routes/authGuard/AuthGuard";
import GuestGuard from "src/routes/authGuard/GuestGaurd";
import FullLayoutAdmin from "src/layouts/full/FullLayoutAdmin";
import AuthAdminGuard from "./authGuard/AuthAdminGuard";
import FullLayoutAccountant from "src/layouts/full/FullLayoutAccountant";
import AuthAccountantGuard from "./authGuard/AuthAccountantGuard";
import CreatePlans from "src/modules/admin/plans/CreatePlans";
import QuotaInfo from "../modules/quotaAi/QuotaInfo";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

// dashboard
const Dashboard = Loadable(
  lazy(() => import("../modules/dashboard/Dashboard"))
);

// CATEGORIZATION
const IsaCode = Loadable(
  lazy(() => import("../modules/categorization/isaCode/isaCode"))
);
const Category = Loadable(
  lazy(() => import("../modules/categorization/category/Category"))
);
const Product = Loadable(
  lazy(() => import("../modules/categorization/product/Products"))
);

// NOTIFICATION

const Notification = Loadable(
  lazy(() => import("../modules/notification/Notification"))
);

// SUPPLIERS
const Suppliers = Loadable(lazy(() => import("../modules/supplier/Suppliers")));

// INVOICES
const Invoices = Loadable(lazy(() => import("../modules/invoice/Invoices")));
const UploadInvoice = Loadable(
  lazy(() => import("../modules/invoice/components/UploadInvoice"))
);
const InvoicesList = Loadable(
  lazy(() => import("../modules/invoice/components/InvoiceList"))
);

const InvoiceDetails = Loadable(
  lazy(() => import("../modules/invoice/InvoiceDetails"))
);

// TICKETING SYSTEM FOR THE CLIENT USER
const CreateTicket = Loadable(
  lazy(() => import("../modules/ticketing/CreateTicket"))
);

// ACCOUNTANT USER
const ListAllAccountantToUser = Loadable(
  lazy(() => import("../modules/accountant/ListAccountantForUser"))
);
const ListTicket = Loadable(
  lazy(() => import("../modules/ticketing/ListTicket"))
);

// account
const Login = Loadable(lazy(() => import("../modules/account/Login")));
const Register = Loadable(lazy(() => import("../modules/account/Register")));
const ForgotPassword = Loadable(
  lazy(() => import("../modules/account/ForgotPassword"))
);
const ForgotPasswordConfirmationMail = Loadable(
  lazy(() => import("../modules/account/ForgetPasswordMail"))
);

const AuthConfirmationAccount = Loadable(
  lazy(() => import("../modules/account/authForms/AuthConfirmationAccount"))
);

const AccountantResetPassword = Loadable(
  lazy(() => import("../modules/account/authForms/AccountResetPassword"))
);

const TwoSteps = Loadable(lazy(() => import("../modules/account/TwoSteps")));
const Error = Loadable(lazy(() => import("../modules/account/Error")));
const Maintenance = Loadable(
  lazy(() => import("../modules/account/Maintenance"))
);
const ConfirmationEmail = Loadable(
  lazy(() => import("../modules/account/ConfirmationEmail"))
);

const AccountConfirmationProcess = Loadable(
  lazy(() => import("../modules/account/AccountConfirmationProcess"))
);

const PaymentStatus = Loadable(
  lazy(() => import("../modules/payement/PaymentStatus"))
);

// ADMIN DASHBOARD
const DashboardAdmin = Loadable(
  lazy(() => import("../modules/admin/dashboard/DashboardAdmin"))
);

const AdminAccountantDashboard = Loadable(
  lazy(() => import("../modules/admin/dashboard/AdminAccountantDashboard"))
);

const DashboardCostumerSupport = Loadable(
  lazy(() => import("../modules/admin/dashboard/DashboardCostumerSupport"))
);

const AdminHorecaDashboard = Loadable(
  lazy(() => import("../modules/admin/dashboard/AdminHorecaDashboard"))
);

const RecategorizationProduct = Loadable(
  lazy(() => import("../modules/admin/product/RecategorizationProduct"))
);

const DashboardUserEngagement = Loadable(
  lazy(() => import("../modules/admin/dashboard/DashboardUserEngagement"))
);

const DashboardFinancial = Loadable(
  lazy(() => import("../modules/admin/dashboard/DashboardFinancial"))
);

const UserManagement = Loadable(
  lazy(() => import("../modules/admin/account/UserManagement"))
);

const ListTicketAdmin = Loadable(
  lazy(() => import("../modules/admin/ticketing/ListTicketAdmin"))
);

// DISCOUNT
const ListUsersCoupon = Loadable(
  lazy(() => import("../modules/admin/discount/ListUsersCoupon"))
);

const ListCoupon = Loadable(
  lazy(() => import("../modules/admin/discount/AllCouponsList"))
);

const CreateGeneraleCoupon = Loadable(
  lazy(() => import("../modules/admin/discount/CreateGeneralCoupon"))
);

const ListGeneraleCoupon = Loadable(
    lazy(() => import("../modules/admin/discount/ListGlobalCoupon"))
);





// SUPPLIER
const ListSupplierAdmin = Loadable(
  lazy(() => import("../modules/admin/supplier/SupplierList"))
);

const CreateNewCustomer = Loadable(
  lazy(() => import("../modules/admin/account/CreateUserByAdmin"))
);

const GeneralCommunication = Loadable(
  lazy(() => import("../modules/admin/account/GeneralCommunication"))
);

const PlansForAdmin = Loadable(
  lazy(() => import("../modules/admin/plans/GetAllPlansForAdmin"))
);
// ACCOUNTANT
const DashboardAccountant = Loadable(
  lazy(() => import("../modules/accountant/AccountantDashboard"))
);

const ConfirmationAccountantToken = Loadable(
  lazy(() => import("../modules/accountant/ConfirmationAccountantToken"))
);

const ListHorecaAccountant = Loadable(
  lazy(() => import("../modules/accountant/client/ListHorecaForAccountant"))
);

const IsaCodeAccountant = Loadable(
  lazy(() => import("../modules/accountant/isacode/IsaCodeAccountant"))
);

const InvoiceListByCustomerForAccountant = Loadable(
  lazy(() => import("../modules/accountant/invoice/ListInvoiceByCustomer"))
);

const InvoiceDetailsAccountant = Loadable(
  lazy(() => import("../modules/accountant/invoice/InvoiceDetailsByCostumer"))
);

const AccountantSupplierList = Loadable(
  lazy(() => import("../modules/accountant/supplier/AccountantSupplierList"))
);

const AccountantProductList = Loadable(
  lazy(() => import("../modules/accountant/product/ProdutAccountantList"))
);

const AccountantDashboard = Loadable(
  lazy(() => import("../modules/accountant/dashboard/AccountantDashboard"))
);

const AccountantCreateClient = Loadable(
  lazy(() => import("../modules/accountant/client/AccountantCreateClient"))
);

const ConfirmAcceskey = Loadable(
  lazy(() => import("../modules/accountant/ConfirmAcceskey"))
);

// COMONS
const UpdatePersonalData = Loadable(
  lazy(() => import("../modules/account/accountManagement/UpdatePersonalData"))
);

const SubscriptionPage = Loadable(
  lazy(() => import("../modules/subscription/Subscription"))
);

// LANDING PAGES
const LandingPage = Loadable(lazy(() => import("../landingPage/LandingPage")));

// CONTACT US
const Contact = Loadable(lazy(() => import("../landingPage/Contact")));

// TERMS CONDITIONS
const TermsCondition = Loadable(
  lazy(() => import("../landingPage/TermConditions"))
);

// Private Policy
const PrivatePolicy = Loadable(
  lazy(() => import("../landingPage/PolicyPrivate"))
);

// Private Policy
const PlansPage = Loadable(lazy(() => import("../landingPage/PlansPage")));

// SYSTEM OPERATION
const SystemOperationList = Loadable(
  lazy(() => import("../modules/systemOperation/SystemOperationList"))
);


// Ai Quota
const AiQuotaPage = Loadable(
    lazy(() => import("../modules/quotaAi/QuotaInfo"))
);


const Router = [
  // PUBLIC ROUTES
  {
    path: "/",
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard>
    ),
    children: [
      { path: "/auth/404", element: <Error /> },
      { path: "/auth/confirmation", element: <ConfirmationEmail /> },
      {
        path: "/auth/account/confirm",
        element: <AccountConfirmationProcess />,
      },

      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/terms-conditions",
        element: <TermsCondition />,
      },

      {
        path: "/plans",
        element: <PlansPage />,
      },

      {
        path: "/contact",
        element: <Contact />,
      },

      {
        path: "/privacy-policy",
        element: <PrivatePolicy />,
      },

      {
        path: "/accountant/confirm/accesKey",
        exact: true,
        element: <ConfirmationAccountantToken />,
      },

      { path: "/auth/login", element: <Login /> },
      { path: "/auth/register", element: <Register /> },
      { path: "/auth/forgot-password", element: <ForgotPassword /> },
      {
        path: "/auth/forgot-password/confirmation",
        element: <ForgotPasswordConfirmationMail />,
      },
      {
        path: "/auth/account/resetPassword",
        element: <AccountantResetPassword />,
      },
      { path: "/auth/two-steps", element: <TwoSteps /> },
      { path: "/auth/maintenance", element: <Maintenance /> },

      {
        path: "/auth/confirmationMailing",
        element: <AuthConfirmationAccount />,
      },

      {
        path: "/payment/status",
        element: <PaymentStatus />,
      },

      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },

  // ADMIN PORTAIL
  {
    path: "/admin",
    element: (
      <AuthAdminGuard>
        <FullLayoutAdmin />
      </AuthAdminGuard>
    ),
    children: [
      { path: "/admin/dashboard", exact: true, element: <DashboardAdmin /> },
      {
        path: "/admin/dashboard/users-engagement",
        exact: true,
        element: <DashboardUserEngagement />,
      },
      {
        path: "/admin/dashboard/financial",
        exact: true,
        element: <DashboardFinancial />,
      },
      { path: "/admin/notification", exact: true, element: <Notification /> },

      {
        path: "/admin/dashboard/support",
        exact: true,
        element: <DashboardCostumerSupport />,
      },
      {
        path: "/admin/product/categorization",
        exact: true,
        element: <RecategorizationProduct />,
      },

      {
        path: "/admin/dashboard/horeca",
        exact: true,
        element: <AdminHorecaDashboard />,
      },

      {
        path: "/admin/dashboard/plans",
        exact: true,
        element: <PlansForAdmin />,
      },

      {
        path: "/admin/dashboard/plans/horeca",
        exact: true,
        element: <PlansForAdmin />,
      },

      {
        path: "/admin/dashboard/plans/accountant",
        exact: true,
        element: <PlansForAdmin />,
      },


      {
        path: "/admin/dashboard/plans/create",
        exact: true,
        element: <CreatePlans />,
      },

      {
        path: "/admin/dashboard/accountant",
        exact: true,
        element: <AdminAccountantDashboard />,
      },

      {
        path: "/admin/users/management/edit",
        exact: true,
        element: <UserManagement />,
      },

      {
        path: "/admin/users/management/createuser",
        exact: true,
        element: <CreateNewCustomer />,
      },

      {
        path: "/admin/ticketing/list",
        exact: true,
        element: <ListTicketAdmin />,
      },
      {
        path: "/admin/discount/create",
        exact: true,
        element: <ListUsersCoupon />,
      },
      { path: "/admin/discount/list", exact: true, element: <ListCoupon /> },

      {
        path: "/admin/discount/create/generale",
        exact: true,
        element: <CreateGeneraleCoupon />,
      },


      {
        path: "/admin/discount/list/generale",
        exact: true,
        element: <ListGeneraleCoupon />,
      },


      { path: "/admin/category/list", exact: true, element: <Category /> },
      {
        path: "/admin/users/generalcommunication",
        exact: true,
        element: <GeneralCommunication />,
      },

      {
        path: "/admin/supplier/list",
        exact: true,
        element: <ListSupplierAdmin />,
      },

      {
        path: "/admin/invoice/details",
        exact: true,
        element: <InvoiceDetails />,
      },

      { path: "*", element: <Navigate to="/auth/404" /> },

      {path: "/admin/quota/7ff3a8b0-1b0d-4c87-872c-203df2b2ffdb", exact: true, element: <QuotaInfo />},

    ],
  },

  // ACCOUNTANT PORTAIL
  {
    path: "/",

    element: (
      <AuthAccountantGuard>
        <FullLayoutAccountant />
      </AuthAccountantGuard>
    ),
    children: [
      {
        path: "/accountant/dashboard",
        exact: true,
        element: <AccountantDashboard />,
      },

      {
        path: "/accountant/personalData/update",
        exact: true,
        element: <UpdatePersonalData />,
      },

      {
        path: "/accountant/horeca/list",
        exact: true,
        element: <ListHorecaAccountant />,
      },

      {
        path: "/accountant/horeca/create-client",
        exact: true,
        element: <AccountantCreateClient />,
      },

      {
        path: "/accountant/horeca/create-client",
        exact: true,
        element: <AccountantCreateClient />,
      },

      {
        path: "/accountant/horeca/confirm/acceskey",

        element: <ConfirmAcceskey />,
      },

      {
        path: "/accountant/ticketing/create",
        exact: true,
        element: <CreateTicket />,
      },
      {
        path: "/accountant/ticketing/list",
        exact: true,
        element: <ListTicket />,
      },

      {
        path: "/accountant/notification",
        exact: true,
        element: <Notification />,
      },

      {
        path: "/accountant/isacode/list",
        exact: true,
        element: <IsaCodeAccountant />,
      },

      {
        path: "/accountant/invoice/list",
        exact: true,
        element: <InvoiceListByCustomerForAccountant />,
      },

      {
        path: "/accountant/invoice/upload",
        exact: true,
        element: <UploadInvoice />,
      },

      {
        path: "/accountant/invoice/details",
        exact: true,
        element: <InvoiceDetailsAccountant />,
      },
      {
        path: "/accountant/supplier/list",
        exact: true,
        element: <AccountantSupplierList />,
      },

      {
        path: "/accountant/product/list",
        exact: true,
        element: <AccountantProductList />,
      },

      {
        path: "/accountant/generalCommunication",
        exact: true,
        element: <GeneralCommunication />,
      },

      {
        path: "/accountant/subscription/all",
        exact: true,
        element: <SubscriptionPage />,
      },

      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },

  // HORECA PORTAIL
  {
    path: "/",

    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: "/horeca/dashboard", exact: true, element: <Dashboard /> },
      //{ path: "/isaCode", exact: true, element: <IsaCode /> },
      { path: "/horeca/products", exact: true, element: <Product /> },
      { path: "/horeca/suppliers", exact: true, element: <Suppliers /> },
      { path: "/invoices", exact: true, element: <Invoices /> },
      {
        path: "/horeca/invoice/upload",
        exact: true,
        element: <UploadInvoice />,
      },

      {
        path: "/horeca/personalData/update",
        exact: true,
        element: <UpdatePersonalData />,
      },

      { path: "/horeca/invoice/list", exact: true, element: <InvoicesList /> },
      { path: "/invoice/details", exact: true, element: <InvoiceDetails /> },
      { path: "/notification", exact: true, element: <Notification /> },
      { path: "/horeca/ticket/create", exact: true, element: <CreateTicket /> },
      { path: "/horeca/ticket/list", exact: true, element: <ListTicket /> },
      {
        path: "/horeca/accountant/list",
        exact: true,
        element: <ListAllAccountantToUser />,
      },

      {
        path: "/horeca/system/operation",
        exact: true,
        element: <SystemOperationList />,
      },

      {
        path: "/horeca/subscription/all",
        exact: true,
        element: <SubscriptionPage />,
      },

      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
