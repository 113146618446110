import {
    GETALLPRODUCTTOBECATEORIZED,
    GETALLCATEGORYPARENT,
    GETALLSUBCATEGORYBYPARENT,
    RESETESUBCATEGORY, RESET_PRODUCT_LIST,
} from "src/modules/admin/product/service/ProductActionTypes";

const initialState = {
    // LIST OF COUPON
    currentPage: 0,
    totalPages: 0,
    totalElements: 0,
    allProductNotCategorized: [],

    listCategoryParent: [],
    listSubCategoryByParent: [],
};

export default function AdminProductReducer(state = initialState, action) {
    switch (action.type) {
        case GETALLPRODUCTTOBECATEORIZED:
            initialState.allProductNotCategorized = []

            return {
                ...state,
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements,
                allProductNotCategorized: action.payload.data,
            };




        case RESET_PRODUCT_LIST:

            return {
                ...state,
                currentPage: 0,
                totalPages: 0,
                totalElements: 0,
                allProductNotCategorized: [],
            }

        case GETALLCATEGORYPARENT:
            return {
                ...state,
                listCategoryParent: action.payload.data.map(item => ({
                    ...item,
                    name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
                })),
            };

        case GETALLSUBCATEGORYBYPARENT:
            return {
                ...state,
                listSubCategoryByParent: action.payload.data,
            };

        case RESETESUBCATEGORY :
            return {
                ...state,
                listSubCategoryByParent: [],
            };

        default: {
            return state;
        }
    }
}
