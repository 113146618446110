import React from 'react';
import {Card, CardContent, Box, Typography, Chip, Divider, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import "../style/QuotaStyle.css";

const LocalQuotaCard = ({quotaInfo, getQuotaInfoFromProvider, loadingButton}) => {
    if (!quotaInfo?.currentTotalQuota) return null;

    return (
        <Card sx={{maxWidth: 700,height: 230, boxShadow: 3, borderRadius: 2, p: 1}}>
            <CardContent>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                    <Typography variant="h6" component="div" color="primary">
                        Quota Ai Status
                    </Typography>
                    <Chip label="Local" size="small"/>
                </Box>

                <Divider sx={{my: 2}}/>

                <Stack spacing={2}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography color="text.secondary">
                            Current Total Quota
                        </Typography>
                        <Typography variant="h6">
                            {quotaInfo.currentTotalQuota}
                        </Typography>
                    </Box>

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography color="text.secondary">
                            Total Quota Used Over Time
                        </Typography>
                        <Typography variant="h6">
                            {quotaInfo.totalQuotaUsedOverTime}
                        </Typography>
                    </Box>

                    <LoadingButton
                        variant="contained"
                        color="primary"
                        className="buttonHoverGetQuotaProvider"
                        onClick={getQuotaInfoFromProvider}
                        loading={loadingButton}
                    >
                        Get Quota Provider
                    </LoadingButton>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default LocalQuotaCard;
