import { configureStore } from "@reduxjs/toolkit";
import CustomizerReducer from "./customizer/CustomizerSlice";
import AuthReducer from "./AuthReducer";
import CategoryReducer from "./CategoryReducer";
import ProductsReducer from "./ProductsReducer";
import SupplierReducer from "./SupplierReducer";
import InvoiceReducer from "./InvoiceReducer";
import DashboardReducer from "./DashboardReducer";
import NotificationReducer from "./NotificationReducer";
import AdminAccountReducer from "./admin/AdminAccountReducer";
import StatusTypeReducer from "./StatusTypeReducer";
import TicketingReducer from "./TicketingReducer";
import AdminDashboardReducer from "./admin/AdminDashboardReducer";
import AdminDiscountReducer from "./admin/AdminDiscountReducer";
import AdminSupplierReducer from "./admin/AdminSupplierReducer";
import AccountantReducer from "./accountant/AccountantReducer";
import AccountantInvoiceReducer from "./accountant/AccountantInvoiceReducer";
import AccountantSupplierReducer from "./accountant/AccountantSupplierReducer";
import AccountantProductReducer from "./accountant/AccountantProductReducer";
import AccountantDashboardReducer from "./accountant/AccountantDashboardReducer";
import SystemOperationReducer from "./systemOperation/SystemOperationReducer";
import AccountManagementReducer from "./accountManagement/AccountManagementReducer";
import AdminProductReducer from "./admin/AdminProductReducer";
import PlansReducer from "./plans/PlansReducer";
import QuotaAiReducer from "./quotaAi/AiQuotaReducer";
export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    authentication: AuthReducer,
    categoryReducer: CategoryReducer,
    productsReducer: ProductsReducer,
    supplierReducer: SupplierReducer,
    invoiceReducer: InvoiceReducer,
    dashboardReducer: DashboardReducer,
    notificationReducer: NotificationReducer,
    adminAccountReducer: AdminAccountReducer,
    statusTypeReducer: StatusTypeReducer,
    ticketingReducer: TicketingReducer,
    adminDashboardReducer: AdminDashboardReducer,
    adminDiscountReducer: AdminDiscountReducer,
    adminSupplierReducer: AdminSupplierReducer,
    accountantReducer: AccountantReducer,
    accountantInvoiceReducer: AccountantInvoiceReducer,
    accountantSupplierReducer: AccountantSupplierReducer,
    accountantProductReducer: AccountantProductReducer,
    accountantDashboardReducer: AccountantDashboardReducer,
    systemOperationReducer: SystemOperationReducer,
    accountManagementReducer: AccountManagementReducer,
    adminProductReducer:AdminProductReducer,
    plansReducer:PlansReducer,
    aiQuotaReducer: QuotaAiReducer,
  },
});

export default store;
